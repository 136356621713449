<template>
  <div
    class="base-price-range"
    :class="[
      `base-price-range--${props.styleType}`,
      { 'base-price-range-main': props.isMain },
    ]"
  >
    <div class="chart-wrapper">
      <span v-if="!max" class="no-objs text-black-monochrome font--b5"
        >No matches =( <br >
        Check out later.
      </span>
      <Bar
        v-else
        ref="priceChart"
        :data="chartData"
        :options="chartOptions"
        style="justify-self: center"
      />
      <vue-slider
        v-if="max"
        v-model="range"
        :min="Math.trunc(min)"
        :max="Math.ceil(max)"
        :tooltip="tooltipFormatter"
        :dot-size="10"
        :height="1"
        :interval="0.1"
        @drag-end="sendRange"
        @change="updateChart"
        @dragging="handleDragging"
      />
    </div>
    <div v-if="max" class="price-range">
      <span class="price-input">
        <input
          v-model.number="InputminPrice"
          type="text"
          :style="{ width: getInputW.min + 'px' }"
          @input="
            () => {
              let newVal = onlyNumbers(InputminPrice);
              $nextTick(() => {
                InputminPrice = newVal;
              });
            }
          "
          @blur="updateByInput"
        >
      </span>
      <base-separator />
      <span class="price-input">
        <input
          v-model.number="InputmaxPrice"
          :style="{ width: getInputW.max + 'px' }"
          type="text"
          @input="
            () => {
              const newVal = onlyNumbers(InputmaxPrice);
              $nextTick(() => {
                InputmaxPrice = newVal;
              });
            }
          "
          @blur="updateByInput"
        >
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "vue-chartjs";
import { onlyNumbers } from "~/utilities/helpers/format-data/onlyNumbers";
import VueSlider from "vue-3-slider-component";

ChartJS.register(...registerables);

interface EmitValue {
  price: {
    gte: number;
    lte: number;
  };
}

interface Emits {
  (event: "emitRange", value: EmitValue): void;
}

interface Props {
  minPrice: number | undefined;
  maxPrice: number | undefined;
  range: { [key: number]: number };
  styleType?: "base" | "filters" | "mobile";
  dropFunc?: () => Function;
  updateFunc?: () => Function;
  noDelay?: boolean;
  isMain?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  styleType: "base",
});

const emit = defineEmits<Emits>();
const route = useRoute();

const getQueryRange = () => {
  const gte = parseInt(route.query["price[gte]"] as string);
  const lte = parseInt(route.query["price[lte]"] as string);
  if (!isNaN(gte) && !isNaN(lte)) {
    return [gte, lte];
  }
  return [Math.trunc(props.minPrice || 0), Math.trunc(props.maxPrice || 100)];
};

const range = ref(getQueryRange());
const priceChart = ref();
const tooltipFormatter = "";
const InputminPrice = ref<number | undefined>(100);
const InputmaxPrice = ref<number | undefined>(500);
const isDragging = ref(false);

const sortedPairs = computed(() => {
  const pairs = labelsComputed.value.map((label, index) => ({
    label: parseFloat(label.toString()),
    price: parseFloat(pricesComputed.value[index].toString()),
  }));

  pairs.sort((a, b) => a.price - b.price);

  return {
    sortedLabels: pairs.map((pair) => pair.label),
    sortedPrices: pairs.map((pair) => pair.price),
  };
});

const pricesComputed = computed(() =>
  props.range ? Object.keys(props.range).map(Number) : []
);

const labelsComputed = computed(() =>
  props.range ? Object.values(props.range) : []
);
const sortedLabels = computed(() => sortedPairs.value.sortedLabels);
const sortedPrices = computed(() => sortedPairs.value.sortedPrices);
const getInputW = computed(() => {
  let min: number;
  let max: number;
  if (!InputminPrice.value) {
    min = 14;
  } else {
    min = InputminPrice.value.toString().length * 11;
  }
  if (!InputmaxPrice.value) {
    max = 14;
  } else {
    max = InputmaxPrice.value.toString().length * 11;
  }
  return { min, max };
});
const min = ref(0);
const max = ref(0);
const chartData = ref({
  labels: sortedPrices.value,
  // labels: props.prices.map((_, index) => index),
  datasets: [
    {
      label: "Price",
      data: sortedLabels.value,
      fill: false,
      backgroundColor: "#B7A2F3",
      barThickness: "flex",
      // maxBarThickness: 8,
    },
  ],
});

const chartOptions = ref({
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      display: false,
      barPercentage: 1.4,
    },
    y: {
      display: false,
      beginAtZero: true,
    },
  },
});

let timeout: ReturnType<typeof setTimeout>;

const updateChart = (byInput?: number[]) => {
  nextTick(() => {
    let [min, max] = range.value;
    if (Array.isArray(byInput)) {
      [min, max] = byInput;
      range.value = [min, max];
    }
    InputminPrice.value = min || 0;
    InputmaxPrice.value = max || 0;
    chartData.value.labels = sortedPrices.value;
    chartData.value.datasets[0].data = sortedLabels.value;
    chartData.value.datasets[0].backgroundColor = sortedPrices.value.map(
      (price: any) => (price >= min && price <= max ? "#B7A2F3" : "#E0E0E0")
    );
    if (
      priceChart.value?.chart &&
      typeof priceChart.value.chart.update === "function"
    ) {
      priceChart.value.chart.data.datasets = chartData.value.datasets;
      priceChart.value?.chart.update();
    }
  });
};

const sendRange = () => {
  isDragging.value = false;
  timeout = setTimeout(
    () => {
      if (isDragging.value) {
        clearTimeout(timeout);
        return;
      }

      // emit("emitRange", range.value);
      emit("emitRange", {
        price: { gte: range.value[0], lte: range.value[1] },
      });
    },
    props.noDelay ? 0 : 1500
  );
};

const updateByInput = () => {
  const arr = [
    Math.trunc(InputminPrice.value || 0),
    Math.trunc(InputmaxPrice.value || 0),
  ];
  updateChart(arr);
  sendRange();
};

const chartW = computed(() => 8 * sortedPrices.value.length);

const handleDragging = (event: any) => {
  isDragging.value = true;
};

const dropPriceRange = (numbers: number[]) => {
  nextTick(() => {
    numbers = numbers?.map((n) => {
      n = Math.ceil(n);
      return n;
    }) || [0, 0];
    range.value[0] = Math.trunc(numbers[0]) || 0;
    range.value[1] = Math.ceil(numbers[1]) || 50;
    min.value = range.value[0];
    max.value = range.value[1];
    updateChart();
  });
};

onMounted(() => {
  nextTick(() => {
    const gte =
      typeof route.query[`tokenPrice[gte]`] === "string"
        ? route.query[`tokenPrice[gte]`]
        : props.minPrice;
    const lte =
      typeof route.query[`tokenPrice[lte]`] === "string"
        ? route.query[`tokenPrice[lte]`]
        : props.maxPrice;
    min.value = Math.trunc(computed(() => props.minPrice).value);
    max.value = Math.ceil(computed(() => props.maxPrice).value);
    range.value = [Math.trunc(gte), Math.ceil(lte)];
    updateChart();
  });
  if (typeof props.dropFunc === "function") {
    const drop = props.dropFunc();
    drop(dropPriceRange);
  }
  if (typeof props.updateFunc === "function") {
    const update = props.updateFunc();
    update(updateChart);
  }
});
</script>

<style scoped lang="scss">
.base-price-range {
  position: relative;
  display: grid;
  width: v-bind(chartW);
  min-width: 260px;
  max-width: 260px;
  gap: 24px;
  .chart-wrapper {
    position: relative;
    text-align: center;
    .no-objs {
      // position: absolute;
      text-align: center;
      margin: auto;
      width: 100%;
      left: 0;
      top: 1.1rem;
    }
    canvas {
      max-height: 80px !important;
    }

    :deep(.vue-slider-process) {
      background: var(--black-monochrome-60);
    }
    :deep(.vue-slider-dot-handle) {
      background: var(--violet-main);
      border: 1px solid white;
    }
  }
  .price-range {
    display: flex;
    align-items: center;
    gap: 9px;
    .price-input {
      position: relative;
      display: flex;
      max-width: 110px;
      width: 110px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid var(--black-monochrome-60);
      background: white;
      input {
        // width: 110px;
        height: 100%;
        outline: none;
        border: none;
        background: transparent;
        color: #000;
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
      &::after {
        content: "$";
        position: relative;
        top: -1px;
        // right: 8px;
        color: var(--Monochrome-Black-60, rgba(18, 18, 18, 0.6));
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  &--filters {
    width: fit-content;
    min-width: 95%;
    max-width: 95%;
    justify-self: center;
    .chart-wrapper {
      canvas {
        max-height: 35px !important;
      }
      .vue-slider {
        max-width: 97% !important;
      }
    }
    .price-range {
      width: 100%;
      gap: unset;
      justify-content: space-between;
      .base-separator {
        width: 7px;
      }
      .price-input {
        width: 74px;
        height: 32px;
        padding: 6px 8px;
        border-radius: 6px;
        background: transparent;
        input {
          max-width: 60px;
          min-width: 14px;
          color: var(--black-monochrome-60);
        }
      }
    }
  }
  &--mobile {
    min-width: 313px;
    max-width: 313px;
    .price-range {
      .base-separator {
        width: 16px;
      }
      .price-input {
        max-width: 140px;
        width: 100%;
      }
    }
  }
  &-main{
    .price-range{
      .price-input{
        min-width: 110px;
        padding: 10px 30px 10px 24px;
        input{
          max-width: 65px;
        }
      }
    }
  }
}
</style>
